import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import routes from './conf/routes';
//import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { BrowserRouter} from "react-router-dom";


/*PACKAGES TO TERMINAL
yarn add react-google-recaptcha ( yarn add @types/react-google-recaptcha ) 
yarn add axios     ( yarn add @types/axios )
yarn add bootstrap
yarn add react-parallax
yarn add react-responsive-carousel
yarn add react-bootstrap
yarn add webpack
yarn add webpack-dev-server
yarn add webpack-cli
*/




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter > 
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

/*
<Router>
    <Fragment>
          {routes.map( ({ path, component, name}) => { //routes objectet har en path, namn och component som properties, 
            return <Route exact path={path} key={name} component={component} /> //här specificerar vi property namnen för våra objekt
          })}
    </Fragment>
</Router> 
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
