import React,{ Component, Fragment, useNavigate } from "react";
import "./NavbarStyles.css";

import { useMediaQuery } from 'react-responsive';

//import Student from "../pages/studentinfo";
//import Company from "../pages/companyinfo";
//import App from "../../App";

import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 
import ssturecbanner from './ssturecbanner.png';
import ssturecbanner2 from './smallssturecbanner.png';
// Important info for refreshing page on for example /student https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writing-manually?page=1&tab=scoredesc#tab-top 

/* CODE
yarn add react-responsive

*/


// video https://www.youtube.com/watch?v=23BHwAFIZmk&ab_channel=Tech2etc



class Navbar extends Component {
  state = { clicked: false };

  
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };


  handleStartClick = () => {
    window.location.href='/';
  };

  render() {


    return (
      <>
        <nav>
          <a  href="/" onClick={this.state.clicked && this.handleClick}> {/*href uppdaterar webläsaren och to byter bara vad som visas */}
            <img className="banner1" src={ssturecbanner}></img>
            <img className="banner2" src={ssturecbanner2}></img>

            {/*<svg //för loggan
              id="logo-15"
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <path //varje path är en cirkel i loggan
                d="M24.5 12.75C24.5 18.9632 19.4632 24 13.25 24H2V12.75C2 6.53679 7.03679 1.5 13.25 1.5C19.4632 1.5 24.5 6.53679 24.5 12.75Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
              <path
                d="M24.5 35.25C24.5 29.0368 29.5368 24 35.75 24H47V35.25C47 41.4632 41.9632 46.5 35.75 46.5C29.5368 46.5 24.5 41.4632 24.5 35.25Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
              <path
                d="M2 35.25C2 41.4632 7.03679 46.5 13.25 46.5H24.5V35.25C24.5 29.0368 19.4632 24 13.25 24C7.03679 24 2 29.0368 2 35.25Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
              <path
                d="M47 12.75C47 6.53679 41.9632 1.5 35.75 1.5H24.5V12.75C24.5 18.9632 29.5368 24 35.75 24C41.9632 24 47 18.9632 47 12.75Z"
                className="ccustom"
                fill="#e0972a"
              ></path>{" "}
            </svg> */}
          </a>

          <div>  {/* to="/company" target="_blank" eller onClick={() => {window.open("/company", "_blank");}}  funkar "_self" funkar även för egen länk*/}
            <ul
              id="navbar"
              className={this.state.clicked ? "#navbar active" : "#navbar"}
            >
              <li>
                <NavLink end href="/" to="/" onClick={this.handleStartClick}>Start</NavLink> {/* Navlink gör automatiskt den länk man är på till aktiv, end gör att man måste vara inne på den exakta länken för att vara aktiv  https://stackoverflow.com/questions/64718464/navlink-exact-prop-not-working-for-react-router-dom-6 */}
              </li>
              <li>
              <NavLink end to="/company" target="_self" onClick={this.state.clicked && this.handleClick} >Företag</NavLink> 
              </li>
              <li>
                <NavLink end to="/student" target="_self" onClick={this.state.clicked && this.handleClick}>Student</NavLink>
              </li>
              
              {/*<li>
              <NavLink end to="/about" target="_self" onClick={this.state.clicked && this.handleClick}>Om Oss</NavLink>
              </li>*/}
              {/* <li>
                <CustomLink onClick={() => {window.open("/student", "_self");}}>About</CustomLink>
              </li> */}
            </ul>
          </div>

          <div id="mobile" onClick={this.handleClick}>
            <i
              id="bar"
              className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </nav>




      </>
    
    );
  }
}




export default Navbar;
