import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./aboutinfo.css"; 

import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 



/* react responsive carousel 
Install: https://www.npmjs.com/package/react-responsive-carousel OR  http://react-responsive-carousel.js.org/#install
react responsive carousel settings http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base

*/


import infosvg from './aboutsvgs/info.svg';
import worksvg from './aboutsvgs/briefcase.svg';
import studentsvg from './aboutsvgs/studentwhite.svg';

/* SVGS
Students https://freeicons.io/search/icons?q=student&iuc=4251998142
Briefcase https://freeicons.io/search/icons?q=briefcase&iuc=4251998142
Info

*/

export default function AboutInfo() {
  return ( //<Col xs={1} /> 
    
<div className='all-about-content-grid' align="center">  

    <div className='all-about-content' align="center">  
        
        <div className="about-sides ipadphone-gone"><p></p></div> 
        
            <div className='about-content'>
                <br/>
                <img src={infosvg} alt="SVG logo image" className="svg-style"/>
                <h3 className='aboutheader'>Om Oss</h3>
                <p className='abouttext'> 
                Vårt huvudsakliga mål på Sundsvall Student Recruiting är att skapa meningsfulla anställningar och hjälpa både företag och studenter utöka sina nätverk. Vi gör detta genom att underlätta anställningsprocesser och genom att sammanföra intressanta företag med ambitiösa studenter. <br/>Vi har nätverket, har ni viljan?                
                <br/>Kontakta oss  <NavLink className="hover-underline-animation" end to="/contact" target="_self" >här</NavLink>.                
                
                </p> {/* Text styles https://getbootstrap.com/docs/5.0/utilities/text/*/}
            </div> 

            <div className='about-content mid'>
                <div className='mid-border'>
                    <br/>
                    <img src={studentsvg} alt="SVG logo image" className="svg-style"/>
                    <h3 className='aboutheader'>Student</h3>
                    <p className='abouttext'> 
                    Oavsett om du är ute efter heltid, deltid eller examensarbete vet vi hur svårt det kan vara att ta steget ut i arbetslivet, man vet sällan vilka företag som finns, vilken kompetens de behöver eller vad man själv vill jobba med. Vi hjälper dig att identifiera detta samt delar med oss av vårt breda nätverk för att ge dig en fördel på arbetsmarknaden.                     
                    <br/>Klicka <NavLink className="hover-underline-animation"  end to="/student" target="_self">här</NavLink> för mer information.


                    </p> {/* Text styles https://getbootstrap.com/docs/5.0/utilities/text/*/}
                </div>
            </div>


            <div className='about-content'>        
                <br/>
                <img src={worksvg} alt="SVG logo image" className="svg-style"/>
                <h3 className='aboutheader'>Företag</h3>
                <p className='abouttext'> 
                Att hitta personal som både är kompetent och passar in är oftast väldigt svårt, dyrt och tidskrävande. Vi hjälper er fylla de kompetensbrister ni saknar och ser till att skapa riktiga anställningar. Med åtkomst till vårt breda studentnätverk som vi byggt upp sedan 2017 hittar vi kompetens oavsett önskat område.
                <br/>Klicka <NavLink className="hover-underline-animation"  end to="/company" target="_self">här</NavLink> för mer information.

  </p> {/* Text styles https://getbootstrap.com/docs/5.0/utilities/text/*/}
            </div>

        <div className="about-sides ipadphone-gone"><p></p></div> 

    </div>
</div> 
);
}
//http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--fade&knob-showArrows_Toggles=true&knob-showStatus_Toggles=&knob-showIndicators_Toggles=true&knob-infiniteLoop_Toggles=true&knob-showThumbs_Toggles=true&knob-useKeyboardArrows_Toggles=true&knob-autoPlay_Toggles=true&knob-stopOnHover_Toggles=true&knob-swipeable_Toggles=true&knob-dynamicHeight_Toggles=true&knob-emulateTouch_Toggles=true&knob-autoFocus_Toggles=&knob-thumbWidth_Values=100&knob-selectedItem_Values=1&knob-interval_Values=10000&knob-transitionTime_Values=500&knob-swipeScrollTolerance_Values=5
//ReactDOM.render(<Successstories />, document.querySelector('.demo-carousel'));



