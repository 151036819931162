import React, { Component, useState, useEffect  } from 'react';
import ReactDOM from 'react-dom';
import { Parallax } from 'react-parallax';
import { useMediaQuery } from 'react-responsive';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 

//Exempel: https://www.teamtalex.com/our-story        https://www.tate.org.uk/about-us    https://cupcakesandcashmere.com/page/about

import "./pagecss/infopagestemplate.css"; 
import "./pagecss/studentinfo.css"; 



import useCollapse from 'react-collapsed';


import plussvg from '../aboutinfo/aboutsvgs/plussvg.svg';
import minussvg from '../aboutinfo/aboutsvgs/minussvg.svg';


import Contactwidget from '../contactwidget/contactwidget';


/* CODE
yarn add react-collapsed
yarn add react-responsive

*/


/*Parallax START*/
const image1 =
"https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D";

const inlineStyle = {
container: {
  background: '#fff',
  left: '50%',
  top: '50%',
  position: 'absolute',
  padding: '20px',
  transform: 'translate(-50%, -50%)',
},
}; //we need container and as cosnt to not get error:  https://www.kindacode.com/snippet/react-typescript-using-inline-styles-correctly/
/*Parallax END 


    <Parallax bgImage={ image1 } strength={500}>
      <div style={{ height: 475 }}>
        </div>
        </Parallax>

*/
const currentsite = "Studentpage";


export default function Studentinfo() {

  
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(); /* https://blog.logrocket.com/create-collapsible-react-components-react-collapsed/ */

  const isMobile = useMediaQuery({ query: `(max-width: 728px)` }); /* https://stackoverflow.com/questions/44480053/how-to-detect-if-screen-size-has-changed-to-mobile-in-react */

  return ( //<Col xs={1} /> 
  <React.StrictMode> {/* React.strictmode behövs tydligen för parallax */}
    
<section className='contact-section-class'>
  
  <Contactwidget />
  <title> Student Recruiting | Student  </title> {/* TAB TITLE  */}


<div className='subpage-row-grid'> 
  <div className='subpage-row'>
    
    <div className="subpage-side"> 
      <div className='left-subpage-img left-subpage-img-stud side-img-settings'>

      </div>

    </div>

    <div className='subpage-content'>

      <div className='testdiv'>
        <div className='content-overhead content-overhead-stud '/>
      </div>
 

       <div className='benefit-window' {...isMobile &&{...getToggleProps()}} >
          <div className='benefit-textbox'>
            <div className='inline'><h3 /* className='content-rubrik'/* */>Vi erbjuder:</h3> <div className="expandcontractimg" > <img className="togglecolappseimg" src={isExpanded ? minussvg : plussvg}  alt="toggle"/> </div> </div>
            <div {...isMobile &&{...getCollapseProps()}}> {/*https://www.digitalocean.com/community/tutorials/7-ways-to-implement-conditional-rendering-in-react-applications */}
              <ul className='benefit-list'>
              <li>Hjälp att göra CV</li>
              <li>Hitta heltidsarbete</li>
              <li>Hitta deltidsarbete</li>
              <li>Hitta examensarbete</li>
              <li>Identifiera arbetsintressen och karriärvägar</li>
              <li>Hjälper dig få in en fot i arbetslivet</li> 
              <li>Skapar kontakter på arbetsmarknaden</li>
              <li>Förbättra förutsättningarna till anställning</li>
              <li>Helt gratis</li>


              </ul>

              <h3>Vad krävs av mig?</h3> 
                <ul className='benefit-list'>
                  <li>Ha ett färdigt cv </li>
                  <li>Var redo för arbetsintervjuer</li>
                  <li>Ha en positiv inställning</li>
                  <li>Vara arbetsvillig</li>

                </ul>

            </div>
          </div>
        </div>

      {/*<div className='content-text'> */}
      <div className='content-textbox'>
        <h1 className='content-rubrik'>För Studenter</h1>
        <br/>
        <p className='content-text'>
        Som tidigare studenter på Mittuniversitetet vet vi på Sundsvall Student Recruiting hur svårt det kan vara att komma ut i arbetslivet. En viss osäkerhet finns ofta när man inte riktigt vet vad ett företag är ute efter och om man själv är bra nog för att uppfylla de kraven som företaget har. Dessutom vet man sällan ens vart man ska börja för att få kontakt med företag och ännu mindre vilken typ av arbetsuppgifter man skulle trivas med.  
<br/><br/>
 
Vi på Sundsvall Student Recruiting hjälper dig som student att få in en fot i stadens arbetsliv genom att hitta spännande företag och arbetsuppgifter som passar dig utifrån dina mål och intressen. Vi hjälper till att hitta heltid, deltid och examensarbeten (C och D uppsatser). Allt detta och mer hjälper vi dig med gratis. Att hitta ett jobb kan ibland vara enkelt, men att hitta ett jobb som man både trivs och utvecklas på är mycket svårare. Det är här vi på Sundsvall Student Recruiting kan hjälpa dig att hitta rätt.        
 
<br/><br/>
Ett misstag många tidigare studenter uttryckt är att de ångrat att de inte börjat jobba inom sitt utbildningsområde redan under studietiden. Under de första åren av studietiden ska man absolut se till att fokusera på skolan och ha så kul det bara går, men samtidigt kan det vara fördelaktigt att börja förbereda sig inför examen och framtiden. Studenter som börjat arbeta under studietiden känner sig ofta mer självsäkra och attraktiva på arbetsmarknaden vid examen. Dessa studenter har också fått bättre uppfattning inom vilket område de vill fortsätta arbeta med samt att de har större potential till högre ingångslön.   
<br/><br/>
 
<h5>Vad förväntas av mig som student?</h5> 
<li>Ha ett färdigt cv </li>
<li>Var redo för arbetsintervjuer</li>
<li>Ha en positiv inställning</li>
<li>Vara arbetsvillig</li>
<br/>

Vill du ha mer <NavLink end to="/contact" target="_self"  className="hover-underline-animation"  >information </NavLink> så hör av dig, vi svarar gärna på dina frågor eller sätter upp ett förutsättningslöst möte.
        
        
      </p>





        </div>
      {/*</div>*/}
      
    </div>


    <div className="subpage-side">
      <div className='right-subpage-img right-subpage-img-stud side-img-settings'>

      </div>

    </div>

  </div> 

</div>


</section>
  </React.StrictMode>

);
}
