import React from 'react';

import Footer from './components/footer/footer';

import Navbar from "./components/navbar/navbar";


import Company from "./components/pages/companyinfo"
import Home from "./components/pages/homepage"
import Student from "./components/pages/studentinfo"
import Scrolltocontact from "./components/pages/scrolltocontact"
import About from "./components/pages/about"



import { Route, Routes, Navigate } from "react-router-dom"


import "./universal.css"; 

/*
yarn add react-router-dom


*/





function App() {
  return (
    <React.StrictMode> {/* React.strictmode behövs tydligen för parallax */}
    <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Scrolltocontact />} />
          <Route path="/company" element={<Company />} /> {/* */}
          <Route path="/student" element={<Student />} />
          {/*<Route path="/about" element={<About />} /> */}


          <Route path="*" element={<Home />}/>
          {/*<Route path="*" element={<Navigate to="/" path="/" replace />}/> {/* Redirect function; if not valid url, redirect to homepage  https://stackoverflow.com/questions/69868956/how-can-i-redirect-in-react-router-v6 */}
        
        </Routes>

    <Footer />

    </React.StrictMode>
  );
}

export default App;
