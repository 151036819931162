import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./footer.css"; 


/* react responsive carousel 
Install: https://www.npmjs.com/package/react-responsive-carousel OR  http://react-responsive-carousel.js.org/#install
react responsive carousel settings http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base

*/


import emailsvg from './footersvgs/email.svg';
import linkedinsvg from './footersvgs/linkedin.svg';
import communicationsvg from './footersvgs/communication.svg';
import phonesvg from './footersvgs/phone3.svg';
import footerlogo from './footersvgs/logo-footer.png';


/* SVGS
Email https://freeicons.io/search/icons?q=email&iuc=4251998142

Phone 
https://freeicons.io/search/icons?q=phone&iuc=4251998142

linkedin
https://www.onlinewebfonts.com/icon/432369

*/

export default function AboutInfo() {
  return ( //<Col xs={1} /> 
    
    
    
    
<div className='footer-border'>   
    <div className='all-footer-content-grid' align="center">  
     <div className='all-footer-content' align="center">  
           
            <div className="footer-sides ipadphone-gone"><p></p></div> 
        
            <div className='footer-content'>
                <div className='only-contact-footer'>
                <br/><div className='contact-row'> <a href='mailto:info@ssturec.se'><img src={emailsvg} alt="SVG logo image" className="svg-footer-style svg-footer-style-email"/></a>
                <br className='phone-footer-contact-info' /><a className="footer_links" href='mailto:info@ssturec.se' ><span className='footer-contact'><p className="hover-underline-animation">info@ssturec.se</p></span></a> </div>

                <div className='contact-row'> <a href='tel:+46733588000'><img src={phonesvg} alt="SVG logo image" className="svg-footer-style"/></a>  
                <br className='phone-footer-contact-info' /><a className="footer_links" href='tel:+46733588000' ><span className='footer-contact'><p className="hover-underline-animation">073-35 88 000</p></span></a> </div>
                
                <div className='contact-row contact-row-lin'> <a href='https://www.linkedin.com/company/sundsvall-student-recruiting/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="svg-footer-style"/></a>  
                <br className='phone-footer-contact-info' /><a className="footer_links" ><span className='footer-contact'>{/*Sundsvall Student Recruiting*/}</span></a> </div>
                </div>

                <a className="madeby_link" href='https://www.instagram.com/adlerbornsketch/' target="_blank"><p className="hover-underline-animation">Logo made by Caroline Adlerborn</p> </a>

            </div>



            <div className='footer-content mid-content'>
                <a className="footer_links" href='/' ><img src={footerlogo} alt="SVG logo image" className="logo-footer-style"/></a> {/* for logo */}
                <div className='helped-companies'/>
      

                {/* LATER VERSION, ABOVE IS TEMPORARY UNTILL I GET CUSTOMERS
                <div className='helped-companies'>
                    <p className='helped-companies-text'> Companies we have helped: </p> 
                    <a href='http://www.google.se/' target="_blank"><img src={phonesvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={phonesvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={phonesvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                    <a href='http://www.google.se/' target="_blank"><img src={linkedinsvg} alt="SVG logo image" className="helped-svg-footer-style"/></a>
                </div> */}

            </div>



            <div className='footer-content left-content'>        
                <br/>
                {/*<img src={phonesvg} alt="SVG logo image" className="svg-footer-style"/>
                <h3>Hello User:</h3> */}
                <a className="madeby_link" href='http://www.freddeholm.se/' target="_blank"><p className="hover-underline-animation">Site made by Fredde Holm</p> </a>
                    
                 
            </div>

            <div className="footer-sides ipadphone-gone"><p></p></div> 
     </div>
    </div>
</div>  
);
}
//http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--fade&knob-showArrows_Toggles=true&knob-showStatus_Toggles=&knob-showIndicators_Toggles=true&knob-infiniteLoop_Toggles=true&knob-showThumbs_Toggles=true&knob-useKeyboardArrows_Toggles=true&knob-autoPlay_Toggles=true&knob-stopOnHover_Toggles=true&knob-swipeable_Toggles=true&knob-dynamicHeight_Toggles=true&knob-emulateTouch_Toggles=true&knob-autoFocus_Toggles=&knob-thumbWidth_Values=100&knob-selectedItem_Values=1&knob-interval_Values=10000&knob-transitionTime_Values=500&knob-swipeScrollTolerance_Values=5
//ReactDOM.render(<Successstories />, document.querySelector('.demo-carousel'));



