import React, { Component, useRef, useEffect } from 'react';
import ReactDOM, { render } from 'react-dom';

import contactsvg from '../aboutinfo/aboutsvgs/contactsvg.svg';
import "./contactwidget.css"; 

import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 


/* react responsive carousel 
Install: https://www.npmjs.com/package/react-responsive-carousel OR  http://react-responsive-carousel.js.org/#install
react responsive carousel settings http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--base

*/

/* SVGS
Students https://freeicons.io/search/icons?q=student&iuc=4251998142
Briefcase https://freeicons.io/search/icons?q=briefcase&iuc=4251998142
Info

*/

export default function Contactwidget({currentsite}) {
  

  const refreshPage = () => {
    window.location.href="/contact";
  }
  
  
  return ( //<Col xs={1} /> 
  
    
<div className='contact-container'>
  <NavLink end to="/contact" onClick={refreshPage} target="_self" className="contactlink"><img className="contactsvg" src={contactsvg}  alt="toggle"/></NavLink>
  {/*<div onClick={currentsite === "Homepage" ?  () => window.location.href = "/contact"  : () => window.location.href = "/contact"} className="contactlink">
    <img className="contactsvg" src={contactsvg}  alt="toggle"/>
</div>*/}
</div>  
);
}
//http://react-responsive-carousel.js.org/storybook/?path=/story/01-basic--fade&knob-showArrows_Toggles=true&knob-showStatus_Toggles=&knob-showIndicators_Toggles=true&knob-infiniteLoop_Toggles=true&knob-showThumbs_Toggles=true&knob-useKeyboardArrows_Toggles=true&knob-autoPlay_Toggles=true&knob-stopOnHover_Toggles=true&knob-swipeable_Toggles=true&knob-dynamicHeight_Toggles=true&knob-emulateTouch_Toggles=true&knob-autoFocus_Toggles=&knob-thumbWidth_Values=100&knob-selectedItem_Values=1&knob-interval_Values=10000&knob-transitionTime_Values=500&knob-swipeScrollTolerance_Values=5
//ReactDOM.render(<Successstories />, document.querySelector('.demo-carousel'));



