import React, { useRef, useEffect, useState } from 'react';

import Homepage from './homepage';



function ScrollToContact() {

    //Stopppa in usestate
    const contactScrollHere = useRef(null);


    useEffect(()=>{ //UseEffect laddas direkt när sidan laddas
        scrollToSection(contactScrollHere);
        const Currentsite = "Homepage";
        }, [])
        
    const scrollToSection = (elementRef) => {
        window.scrollTo({
          top: elementRef.current.offsetTop,
          behavior: "smooth",
        });  
    };
    



  return (
<Homepage contactScrollHere={contactScrollHere} />    


  );
}

export default ScrollToContact;