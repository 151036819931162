import React, { ChangeEvent, FormEvent, useRef, useState, useEffect } from "react";
import "./contactform.css";
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';

import 'bootstrap/dist/css/bootstrap.min.css';

import studentsvg from '../aboutinfo/aboutsvgs/studentwhite.svg';
import othersvg from '../aboutinfo/aboutsvgs/other.svg';
import companysvg from '../aboutinfo/aboutsvgs/briefcase.svg';


import "./FormRadioCheckbox.css"


//const axios = require('axios'); 


//import {handleTextArea} from "oneline-textarea"


//DESIGN for contact form: https://bootsnipp.com/snippets/A36DP


//VIDEO// om koden https://www.youtube.com/watch?v=T3NHNuD60h4&ab_channel=JuniorDeveloperCentral 24 min in 
// minst 1 paket måste installeras:  yarn add react-google-recaptcha 


type FormState = { //detta typescript måste ha samma namn på kategorier som const initialFormState
  email: string;
  name: string;
  phone: string;
  message: string;
  profession: string;
  studentprogram: string;
  studentyear: string;
  companyname: string;
  newssubscribe: string;




};

type ServiceMessage = { // hanterar det medelande som kommer upp efter att man klickat submit
  class: string; //bestämmer vilken färg medelandet ska ha
  text: string; //Bestämmer vad det ska stå i medelandet
};


function ContactForm() {
  const [subscribe, setSubscribe] = useState<any>("Nej tack");// Radio button clear code https://surajsharma.net/blog/react-handle-radio-buttons


  const onloadCallback = () => { // Radio button clear code https://surajsharma.net/blog/react-handle-radio-buttons
    recaptchaRef.current.reset(); //När formuläret skickas recaptcha, gör inte detta om mailet inte skickades

  }



// Textresizearea____________________________________________________ //flytta till eget dokument om möjligt
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  // The value of the textarea
  const [value, setValue] = useState<String>();
  // This function is triggered when textarea changes
  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {setValue(event.target.value);};
  useEffect(() => {
    
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);
// Textresizearea____________________________________________________


const formId = "9HpCVM7p"; //unika idt för mitt formulär på formspark:  https://dashboard.formspark.io/workspaces/Bz0sscnB/forms/t17jl8Qu/submissions
const formSparkUrl = `https://submit-form.com/${formId}`; // Write `` using "Shift" + "knapp till vänster om backspace, alltså knappen med ´´ " 
  
const initialFormState = { //denna konstant måste ha samma namn på kategorier som type FormState, här kan man skriva in text som ska vara när formuläret laddas in 
  name: "",
  email: "",
  phone: "",
  profession: "",
  studentprogram: "",
  studentyear: "",
  companyname: "",
  newssubscribe: "Nej tack",
  message: "",

};

//Recaptcha
const recaptchaKey = '6Lec8rseAAAAALV9dXXceNUq48ok3nGWMbAtgwJa'; // för att skaffa recaptcha token https://www.google.com/recaptcha/admin/site/515633820/setup, sitekey skriv in på denna rad och secret key skrivs på formspark https://dashboard.formspark.io/workspaces/Bz0sscnB/forms/t17jl8Qu/settings
const recaptchaRef = useRef<any>();
const [recaptchaToken, setReCaptchaToken] = useState<string>();
const updateRecaptchaToken = (token: string | null) => {
  setReCaptchaToken(token as string);
};


const [checked, setChecked] = useState<any>("");// Radio button clear code https://surajsharma.net/blog/react-handle-radio-buttons

const [formState, setFormState] = useState<FormState>(initialFormState); //laddar in constanterna från initialFormState till FormState så att texten från initialFormState visas i formuläret

const [submitting, setSubmitting] = useState<boolean>(false); //inför en sann/falsk constant som ska ha koll på om formuläret skickas eller ej
const [message, setMessage] = useState<ServiceMessage>(); //hanterar text för om medelandet har skickats eller om error uppstått


const submitForm = async (event: FormEvent) => { //När man klickar på skicka formulär
    event.preventDefault();
    setSubmitting(true); 
    await postSubmission();
    setSubmitting(false);
  };


  const postSubmission = async () => {
    const payload = { //payload är den info som ska skickas till formspark 
      ...formState, //tar all data från kategorierna i formstate och gör som payload så att allt laddas upptill formspark
      "g-recaptcha-response": recaptchaToken, //skickar med recaptcha nyckeln till formspark för att vertifiera om nyckeln är rätt
      //kommentera bort raden ovan om man vill testa hur det blir när inte formuläret skickas
      
      //message: "Test formspark submission", //även statiska medelanden kan skickas
    };

    try { //man försöker skicka, om det lyckas händer koden nedan
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({ 
        class: "text-success", //bakgrundsfärg på medelandet
        text: "Ditt meddelande har skickats, vi hör av oss så fort som möjligt!", // om man lyckas skicka medelandet
      });

      setFormState(initialFormState); //När formuläret skickas tömms alla värden, gör inte detta om mailet inte skickades
      recaptchaRef.current.reset(); //När formuläret skickas recaptcha, gör inte detta om mailet inte skickades
      resetRadioState(); // Radio button clear code




    } catch (error) { //om det blir något fel
      console.log(error);
      setMessage({
        class: "text-danger", //bakgrundsfärg på medelandet
        text: "Meddelandet kunde inte skickas, försök igen eller skicka ett mail direkt till info@ssturec.se.", // om man inte lyckas skicka medelandet
      });
      
    }
  };

  const updateFormControl = ( //denna kodbit gör så att man kan uppdatera formuläret (skriva i det), annars är det endast readonly
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
  };
  
  const updateFormControlRadio = ( //denna kodbit gör så att man kan uppdatera formuläret (skriva i det), annars är det endast readonly
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> // kan vara onChange i input elementet
  ) => {
    setChecked(event.target.value); //Radio button clear

    const { name, value } = event.target;
    const key = name as keyof FormState;

    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    console.log(key);
    console.log(updatedFormState[key]);
    setFormState(updatedFormState);

    const key2 = "newssubscribe" as keyof FormState;
    console.log(key2);
    console.log(updatedFormState[key2]);
    updatedFormState[key2] = "Nej tack";
    setFormState(updatedFormState);
    
    setSubscribe("Nej tack");
    onloadCallback(); //Uppdaterar recaptcha

  };

  const resetRadioState = () => { // Radio button clear code https://surajsharma.net/blog/react-handle-radio-buttons
    setChecked("");
    setSubscribe("Nej tack");

  }
  const nothingishappening = () => { // Radio button clear code https://surajsharma.net/blog/react-handle-radio-buttons
    console.log("this function does nothing");

  }

  const resetSubscribe = () => { // Radio button clear code https://surajsharma.net/blog/react-handle-radio-buttons
    setSubscribe("Nej tack");
    console.log("should reset subsrcribe");
    const key = "newssubscribe" as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = "Nej tack";
    setFormState(updatedFormState); 
  }

  const updateSubscribe = ( //denna kodbit gör så att subscribe fuktionen funkar som planerat
  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  
  if(subscribe === "Nej tack"){
    event.target.value="Ja tack";
    console.log(event.target.value + "should be Ja tack");

  }else if(subscribe === "Ja tack"){
    event.target.value="Nej tack"
    console.log(event.target.value + "should be Nej tack");

  }else{
    console.log("Big error else if statements");
  }

  console.log("Visat efter if else" + event.target.value);

  setSubscribe(event.target.value); //Radio button clear
  console.log("vad säger subscribe? " + subscribe);

  const { id, value } = event.target;
  const key = id as keyof FormState;
  const updatedFormState = { ...formState };
  updatedFormState[key] = value;
  setFormState(updatedFormState); 
};



  return (
    <div  className="get-in-touch" /*style={{backgroundColor: 'blue',}}*/ >
      <div >
      <form className="contact-form row" onSubmit={submitForm} >

        <h1 className="title">Kontakta oss </h1>
          {/*<span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
            </svg>
          </span>*/}  
          


        {message && ( // Visar medelande om man lyckats/misslyckats att skicka formuläret
          <div className={`${message.class}`}>
            {message.text}</div>)}


        
          <div className="form-field col-lg-4 "> {/* röd "require" stjärnahttp://blog.adeel.io/2015/10/18/adding-an-asterisk-to-required-fields-in-bootstrap/ */}
            <input type="text" placeholder=" " className="input-text" onChange={updateFormControl} id="name" required
              value={formState?.name} /*from "type FormState", de värde som är inskrivet i initialformstate */ />
            <label className="label" htmlFor="name" >*Namn</label> {/*Label taggen måste vara under input annars funkar inte klickfunktionen på namnet */}
          </div>

          <div className="form-field col-lg-4 ">
            <input type="email" placeholder=" " className="input-text noresize-textarea" onChange={updateFormControl} id="email" required 
              value={formState?.email} /*from "type FormState", de värde som är inskrivet i initialformstate*/ />
            <label className="label"  htmlFor="email" >*Email</label>
          </div>
          <div className="form-field col-lg-4 ">
            <input type="text" placeholder=" " className="input-text noresize-textarea" onChange={updateFormControl} id="phone"  
              value={formState?.phone} /*from "type FormState", de värde som är inskrivet i initialformstate*/ />
            <label className="label"  htmlFor="phone" >&nbsp;Telefon</label> {/* mellanslag innan telefon https://www.computerhope.com/issues/ch001662.htm */}
          </div>


          
          <fieldset className="floatleft bgcolor">
              <div className=" form-field-radio a25text ">
                <label className=" radiolabel" >*Jag är...</label>
              </div>
              
<div className="mobilesettingsradio">
            <div className=" form-field-radio a25 "> {/* Alla radioknappar måste ha samma namn https://stackoverflow.com/questions/63383898/how-can-i-make-a-react-bootstrap-radio-group-required-with-html-validation*/}
              <input className="checkbox-tools" type="radio" id="Student" name="profession" onChange={updateFormControlRadio}  required checked={checked === 'Student'} value="Student"/>
              <label htmlFor="Student" className="for-checkbox-tools">
              <img src={studentsvg} alt="SVG logo image" className="svg-style-contact svg-student" /><br/>Student</label>
            </div>

            <div className=" form-field-radio a25 "> 
              <input className="checkbox-tools" type="radio" id="Företag" name="profession" onChange={updateFormControlRadio}  required  checked={checked === 'Företag'} value="Företag"/>
              <label htmlFor="Företag" className="for-checkbox-tools">
              <img src={companysvg} alt="SVG logo image" className="svg-style-contact" /><br/>Företag</label>
            </div>

            <div className=" form-field-radio a25 "> 
              <input className="checkbox-tools" type="radio" id="Annat" name="profession"  onChange={updateFormControlRadio}  required checked={checked === 'Annat'} value="Annat"/>
              <label htmlFor="Annat" className="for-checkbox-tools">
              <img src={othersvg} alt="SVG logo image" className="svg-style-contact" /><br/>Annat</label>
            </div>
      </div>
          </fieldset>


          {checked === 'Student' && (
            <div >
              <div className=" row ">
                
            <div className="form-field col-lg-6 "> {/* röd "require" stjärnahttp://blog.adeel.io/2015/10/18/adding-an-asterisk-to-required-fields-in-bootstrap/ */}
            <input type="text" placeholder=" " className="input-text" onChange={updateFormControl} id="studentprogram" required
              value={formState?.studentprogram} /*from "type FormState", de värde som är inskrivet i initialformstate */ />
            <label className="label" htmlFor="studentprogram" >*Program</label> {/*Label taggen måste vara under input annars funkar inte klickfunktionen på namnet */}
          </div>

          <div className="form-field col-lg-6 ">
            <input type="text" placeholder=" " className="input-text noresize-textarea" onChange={updateFormControl} id="studentyear" required 
              value={formState?.studentyear} /*from "type FormState", de värde som är inskrivet i initialformstate*/ />
            <label className="label"  htmlFor="studentyear" >*År</label>
          </div>

            </div>
          </div>
          )}

          {checked === 'Företag' && (
            <div >
              <div className=" row ">
                

                  <div className=" form-field col-lg-12"> {/* röd "require" stjärnahttp://blog.adeel.io/2015/10/18/adding-an-asterisk-to-required-fields-in-bootstrap/ */}
                    <input type="text" placeholder=" " className="input-text" onChange={updateFormControl} id="companyname" required
                      value={formState?.companyname} /*from "type FormState", de värde som är inskrivet i initialformstate */ />
                    <label className=" customlabel label" htmlFor="companyname" >*Företagsnamn</label> {/*Label taggen måste vara under input annars funkar inte klickfunktionen på namnet */}
                  </div>

                <div className="form-field col-lg-12">
                  <p className="label"> Vill du prenumerera <br className="displaynone"/>på vårt nyhetsbrev?</p>
                  <div className="floatright">
                    <input className="checkbox-tools-custom" type="checkbox" onChange={updateSubscribe} id="newssubscribe" value={formState?.newssubscribe} />
                    <label  className={subscribe === 'Ja tack' ? "for-checkbox-tools-custom-colored customcheckbox" : "for-checkbox-tools-custom customcheckbox " } htmlFor="newssubscribe" ><i className='uil uil-coffee mr-3 svg-style-contact'></i><span className="text"> {subscribe === 'Ja tack' ? "Ja" : "Nej" } </span></label>
                  </div>
                </div>{/**/}

              </div>
              
              {/* variant till checkbox
              <div className="form-field col-lg-6">
                  <p className="label"> Prenumerera på <br className="displaynone"/>vårt nyhetsbrev?</p>
                  <div className="floatright">
                    <input className="checkbox-booking" type="checkbox" onChange={updateSubscribe} id="newssubscribe" value={formState?.newssubscribe} />
                    <label  className="customcheckbox for-checkbox-booking" htmlFor="newssubscribe" ><i className='uil uil-coffee mr-3'></i><span className="text"> {subscribe === 'Ja tack' ? "Ja" : "Nej" } </span></label>
                  </div>
                </div>*/}


            </div>

          )}


          <div className="form-field col-lg-12 " >
            <textarea className="input-text noresize-textarea" onChange={updateFormControl} 
            id="message" required value={formState?.message} /*from "type FormState", de värde som är inskrivet i initialformstate */ 
              ref={textareaRef} onInput={textAreaChange}>
                {value}</textarea>
            <label className="label" htmlFor="message" >*Vad kan vi hjälpa dig med? </label>
          </div>
          
          <div className="mobile-contact-align-center" > {/* både class "text-center och style display inline-block behövs för att centrera */}
            <ReCAPTCHA className="recaptcha" style={{display : 'inline-block'}} ref={recaptchaRef} sitekey={recaptchaKey} onChange={updateRecaptchaToken} />
          </div>

          <div className="form-field-submit col-lg-12 mobile-contact-align-center">
            <button className="submit-btn" disabled={submitting} > {/*detta görs så att knappen inte fungerar när man har klickat på skicka */} 
              {submitting ? "Skickar..." : "Skicka"} {/*Om variabeln submit är sann står det submitting på knappen annars står det bara submit */}
            </button>
          </div>

        </form>
      </div>
    </div>
  );
}

export default ContactForm;

