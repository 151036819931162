import React, { Component, useState, useEffect  } from 'react';
import ReactDOM from 'react-dom';
import { Parallax } from 'react-parallax';
import { useMediaQuery } from 'react-responsive';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link, useMatch, useResolvedPath, NavLink } from 'react-router-dom'; 

//Exempel: https://www.teamtalex.com/our-story        https://www.tate.org.uk/about-us    https://cupcakesandcashmere.com/page/about


import "./pagecss/infopagestemplate.css"; 
import "./pagecss/companyinfo.css"; 


import useCollapse from 'react-collapsed';


import plussvg from '../aboutinfo/aboutsvgs/plussvg.svg';
import minussvg from '../aboutinfo/aboutsvgs/minussvg.svg';


import Contactwidget from '../contactwidget/contactwidget';


/* CODE
yarn add react-collapsed
yarn add react-responsive

*/


/*Parallax START*/
const image1 =
"https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D";

const inlineStyle = {
container: {
  background: '#fff',
  left: '50%',
  top: '50%',
  position: 'absolute',
  padding: '20px',
  transform: 'translate(-50%, -50%)',
},
}; //we need container and as cosnt to not get error:  https://www.kindacode.com/snippet/react-typescript-using-inline-styles-correctly/
/*Parallax END 


    <Parallax bgImage={ image1 } strength={500}>
      <div style={{ height: 475 }}>
        </div>
        </Parallax>

*/

const currentsite = "Companypage";


export default function Companyinfo() {

  
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(); /* https://blog.logrocket.com/create-collapsible-react-components-react-collapsed/ */

  const isMobile = useMediaQuery({ query: `(max-width: 728px)` }); /* https://stackoverflow.com/questions/44480053/how-to-detect-if-screen-size-has-changed-to-mobile-in-react */

  return ( //<Col xs={1} /> 
  <React.StrictMode> {/* React.strictmode behövs tydligen för parallax */}
    
<section className='contact-section-class'>
  
  <Contactwidget />
  <title> Student Recruiting | Företag  </title> {/* TAB TITLE  */}


<div className='subpage-row-grid'> 
  <div className='subpage-row'>
    
    <div className="subpage-side"> 
      <div className='left-subpage-img left-subpage-img-comp side-img-settings'>

      </div>

    </div>

    <div className='subpage-content'>

      <div className='content-overhead content-overhead-comp'>

      </div>

       <div className='benefit-window' {...isMobile &&{...getToggleProps()}} >
          <div className='benefit-textbox'>
            <div className='inline'><h3 /* className='content-rubrik'/* */>Vi erbjuder:</h3> <div className="expandcontractimg" > <img className="togglecolappseimg" src={isExpanded ? minussvg : plussvg}  alt="toggle"/> </div> </div>
            <div {...isMobile &&{...getCollapseProps()}}> {/*https://www.digitalocean.com/community/tutorials/7-ways-to-implement-conditional-rendering-in-react-applications */}
              <ul className='benefit-list'>
                  <li>Er kontaktväg till studenterna </li> 
                  <li>Tillgång till varierad studentkompetens</li>
                  <li>Hitta rätt person för arbetet</li>
                  <li>Underlätta rekryteringsarbetet</li>
                  <li>Identifiera personalbehov</li>
                  <li>Matcha studenter efter företagets behov </li>
                  <li>Betala endast vid anställning</li>
              </ul>
            </div>
          </div>
        </div>

      {/*<div className='content-text'> */}
      <div className='content-textbox'>
        <h1 className='content-rubrik'>För Företag</h1>
        <br/>
        <p className='content-text'>
        Marknadens behov av kompetens ändras ständigt och det är därför viktigt att se till att hänga med i utvecklingen genom att ha tillgång till rätt kompetens oavsett utmaning i syfte att främja tillväxt. Att hitta rätt person för ett uppdrag är däremot inte alltid lätt och olika företag kräver olika typer av personer med olika kompetenser. 
<br/><br/>

Under corona har kontakten mellan studenter och företag drastiskt minskat och ännu inte återupptagits samtidigt som allt fler studenter examineras och företagens behov av personal ökar. Det är här vi på Sundsvall Student Recruiting kan hjälpa er. Med över 5 år av aktivt nätverkande bland studenter med olika kompetenser från Mittuniversitetets alla utbildningar, hjälper vi er fylla de kompetensbehov som finns hos er samtidigt som er kontakt med Sundsvalls studentliv återupprättas. Vi gör detta genom att samla era behov och sedan hitta studenter som matchar era behov och vice versa. 
<br/><br/>

Sundsvall Student Recruiting är inget vanligt bemannings/rekryteringsbolag som hyr ut och säljer loss anställda. Vårt fokus är istället att skapa riktiga anställningar mellan arbetsgivare och arbetstagare genom att effektivisera rekryteringsprocessen och reducera mellanhandskostnaderna. Ni betalar alltså endast vid anställning.
<br/><br/>


Utifrån egen erfarenhet vet vi på Sundsvall Student Recruiting hur svårt det kan vara att hitta personal som både passar in i de uppdrag och den företagskultur som finns hos företagen. Vi lägger stor energi på att samla in och hitta studenter som passar de krav ert företag har.
<br/>
<br/>
Vill ni ha mer <NavLink end to="/contact" target="_self"  className="hover-underline-animation"  >information </NavLink> så hör av er, vi svarar gärna på era frågor eller sätter upp ett förutsättningslöst möte. 
        
        </p>





        </div>
      {/*</div>*/}

    </div>


    <div className="subpage-side">
      <div className='right-subpage-img right-subpage-img-comp side-img-settings'>

      </div>

    </div>

  </div> 

</div>


</section>
  </React.StrictMode>

);
}
