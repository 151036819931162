import React, { useRef, useEffect, useState } from 'react';
import ContactForm from '../contactform/contactform';
import { Parallax } from 'react-parallax';
import Successstories from '../successstories/successstories';
import AboutInfo from '../aboutinfo/aboutinfo';

import Contactwidget from '../contactwidget/contactwidget';


import "./pagecss/homepage.css"; 


/*
yarn add react-router-dom


*/



/*Parallax START*/
import Coverimage from './images/sundsvall.jpg';

  /*const image1 =
  "./images/sundsvall.jpg"; */

  /*const inlineStyle = {
  container: {
    background: '#fff',
    left: '50%',
    top: '50%',
    position: 'absolute',
    padding: '20px',
    transform: 'translate(-50%, -50%)',
  },
  }; //we need container and as cosnt to not get error:  https://www.kindacode.com/snippet/react-typescript-using-inline-styles-correctly/
/*Parallax END */

const currentsite = "Homepage";


  
  
  function Homepage  ({contactScrollHere}) {

    useEffect(()=>{ //UseEffect laddas direkt när sidan laddas
      console.log(currentsite);
      }, [])

    const contactScrollHere2 = useRef(null);


  return (
    <React.StrictMode> {/* React.strictmode behövs tydligen för parallax */}
    <title> Student Recruiting | Start </title> {/* TAB TITLE  */}

    <section className='contact-section-class-home' >

    <Contactwidget currentsite={currentsite} contactScrollHere2={contactScrollHere2} />
    <div className='parallax-heigth2'>
      <div className="cover-parallax-home2" />
      <div className='parallax-center-content'><div className='parallax-center-text'>Vi hittar den kompetens ni behöver </div></div> {/* <div className='orange'>S</div> */}
    </div> {/*Vi hittar den kompetens ni behöver    Skapar sammarbeten mellan studenter och företag  */}
    
    {/*<Parallax className="cover-parallax-home"  strength={500}>
      <div style={{ height: 550 }}>
        {/*<div style={inlineStyle.container}>HTML inside the parallax</div>
      </div>
    </Parallax>*/}
    <AboutInfo />
    
    {/* sätt igång Success stories när jag har lyckats anställa folk
    <Successstories /> */}
    <div className='parallax-heigth3'>
      <div className="cover-parallax-home3"  />
      <div className='parallax-center-content'><div className='parallax-center-text'>Vi har nätverket, har ni viljan? </div></div> {/* <div className='orange'>S</div> */}
    </div> {/* Vi har nätverket, ni har viljan       Vi har Sundsvalls framtida arbetsmarknad      Sundsvall växer, vi hjälper er att hänga med i utvecklingen!  */}
    
    <div ref={contactScrollHere}> <div ref={contactScrollHere2}> {/* Fuckar när man använder typescript i app, homepage eller scrolltocantacts*/}
    <ContactForm />
    </div></div>

    </section>

    </React.StrictMode>
  );
}

export default Homepage;